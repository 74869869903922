/* YourComponent.css */

.background-container {
    position: relative;
    overflow: hidden;
    height: 80vh;
  }
  
  .gradient-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(128, 128, 128, 0.5)); /* Adjust colors and opacity as needed */
  }
  
  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire container */
  }
  
  .text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff; /* Set text color */
  }
  
  .text-container h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .text-container p {
    font-size: 1.2rem;
  }
  