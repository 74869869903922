body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.gap {
  height: 15vh;
}
/* css of navbar for transparent background */
.navbar {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: background-color 0.3s ease-in-out;
}

.navbar img{
  width: 200px;
  height: 50px;
}

.navbar.hidden {
  display: none;
}

.navbar .bg-white {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* css for hero section */
.hero-section {
  background-color: #aad9bb;
  background-size: cover;
  padding-top: 20px;
  height: 80vh;
  position: relative;
}

.hero-section .container {
  height: 100%;
}

.hero-section .row {
  height: 100%;
}

.hero-section img {
  width: 100%;
  height: 50vh;
}

.curve-button {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #6da4aa;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.curve-button:hover {
  background-color: #5f908a;
}

.auth-hero-section {
  background: linear-gradient(45deg, #aad9bb, #6da4aa, #9dbc98, #f3ffe3);
  background-size: 400% 400%;
  animation: gradientAnimation 10s ease infinite;
  padding-top: 20px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 1s ease;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.auth-form-section {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
}

.carousel-item img {
  border-radius: 50%;
  width: 80%;
  height: 500px;
  overflow: hidden;
}

.carousel-item h2 {
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 0.9px;
}

.rbcard {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 24px;
}
.rbcard-title {
  font-size: 1.5em;
  margin: 8px 0;
}

.rbcard:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

.footer {
  background-color: #aad9bb;
  padding: 50px 0;
}

.footer ul li a {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  line-height: 2;
}

.footer ul li a:hover {
  color: #007bff;
}

.copyright-text {
  font-size: 14px;
  text-align: left;
}

.footer .social-links a {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #3b5998;
  text-align: center;
  line-height: 40px;
  margin-right: 10px;
}

.footer .social-links a:nth-child(2) {
  background-color: #c4302b;
}

.footer .social-links i {
  color: #fff;
  font-size: 16px;
}
